<template>
  <div>
    <div class="mt-15 px-8" v-if="isLoad">
      <v-row class="px-15">
        <v-col cols="12" v-for="i in 5" :key="i" class="text-left">
          <v-skeleton-loader type="paragraph" class="mt-3"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div class="white grid justify-items-center px-0 py-10 text-left" v-else>
      <v-col
        cols="10"
        sm="6"
        class="pa-0 mt-10 pt-10"
        v-if="dataItems.data.length > 0">
        <div
          class="font-bold text-2xl sm:text-4xl mt-5 mb-10 pb-10 text-capitalize">
          {{ pageName }}
        </div>
        <div v-if="dataItems">
          <div class="mt-5 mb-10">
            <div class="font-lora text-base mb-8" v-if="bahasa">
              BPDLH sedang merekrut untuk pengedaan berikut:
            </div>
            <div class="font-lora text-base mb-8" v-else>
              BPDLH is hiring for these procurements:
            </div>
            <div
              class="pa-0 cursor-pointer"
              v-for="item in dataItems.data"
              :key="item.id"
              @click="goTo(item.id)">
              <div class="font-bold text-lg my-2 text-left">
                {{ item.title || "-" }}
              </div>
              <div class="text-sm pb-3 pt-0">{{ item.lastUpdate || "-" }}</div>
            </div>
            <v-row class="text-left mt-3 py-10" v-if="pageCount > 1">
              <v-spacer />
              <v-col cols="auto">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="10"
                  color="green darken-1"
                  next-icon="navigate_next"
                  prev-icon="chevron_left"></v-pagination>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      <v-col
        cols="11"
        class="pa-0 mt-10 py-10"
        v-if="dataItems.data.length === 0">
        <v-row>
          <v-col cols="12" sm="6">
            <img src="@/assets/images/405.png" width="100%" />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="text-black flex flex-wrap content-center">
            <v-col cols="12" class="text-3xl font-bold pl-0">{{
              bahasa
                ? "Tidak ada data pengadaan yang tersedia"
                : "No Procurement Data Available"
            }}</v-col>
            <v-btn class="btn-primary mt-8" :to="{ name: 'home' }">
              {{ bahasa ? "kembali ke beranda" : "back to home" }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      require: false,
    },
  },
  data() {
    return {
      isLoad: false,
      dataItems: null,
      baseUrl: process.env.VUE_APP_BASE_URL,
      lastUpdated: "",
      per_page: 9,
      page: 1,
      pageCount: 0,
    };
  },
  computed: {
    pageName() {
      return this.language === "ID" ? "Pengadaan" : "Procurement";
    },
    language() {
      return this.bahasa ? "ID" : "ENG";
    },
  },
  watch: {
    language() {
      if (this.pageName.includes("detail")) {
        this.getDetail();
      } else {
        this.getData();
      }
    },
    page() {
      this.getData();
    },
  },
  mounted() {
    if (this.id) {
      this.getDetail();
    } else {
      this.getData();
    }
  },
  methods: {
    goTo(param) {
      this.$router.push({
        name: "procurement-detail",
        params: {
          id: param,
        },
      });
    },
    async getData() {
      this.isLoad = true;
      let param = {
        lang: this.language,
        page: this.page,
      };
      await axios
        .get(this.baseUrl + "/procurement", { params: param })
        .then((response) => {
          if (response.data.code === 200) {
            this.dataItems = response.data.data;
            this.lastUpdated = response.data.data.lastUpdate
              ? moment(response.data.data.lastUpdate).format("DD MMMM YYYY")
              : "-";
            this.page = response.data.data.current_page;
            this.pageCount = response.data.data.last_page;
          }
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoad = false));
    },
    async getDetail() {
      this.isLoad = true;
      let param = {
        lang: this.language,
      };
      await axios
        .get(this.baseUrl + this.urlApi + "/" + this.id, { params: param })
        .then((response) => {
          this.dataItems = response.data.data;
          this.lastUpdated = response.data.data.latUpdate
            ? moment(response.data.data.latUpdate).format("DD MMMM YYYY")
            : "-";
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoad = false));
    },
  },
};
</script>
